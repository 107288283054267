import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { IMG_URL } from '../../config';
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { FaArrowLeft } from 'react-icons/fa'; // Import the back arrow icon

function CategoryDetails() {
  const [categoryData, setCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { category } = useParams();
  const navigate = useNavigate(); // Import useNavigate from 'react-router-dom'

  useEffect(() => {
    axios
      .get('https://pcyag3x4st4hfy6a4ta4jf72du0myucn.lambda-url.ap-south-1.on.aws/')
      .then((response) => {
        console.log('API Response:', response.data);
        // Filter data based on the 'tabName' property
        const filteredData = response.data.results.filter(
          (item) => item.tabName === category
        );

        console.log('Filtered Data:', filteredData);
        setCategoryData(filteredData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, [category]);

  const navigateToShopDetail = (objectId) => {
    navigate(`/shop/${objectId}`);
  };
  const navigateToHome = () => {
    navigate('/'); // Redirect to the home page
  };
  return (
    <div className='container'>

<div className="d-flex justify-content-start align-items-center mt-5 pt-5">
        <button onClick={navigateToHome} className="btn btn-light">
          <FaArrowLeft /> Back
        </button>
        
      </div>
      <h2 className=" text-center">{category}</h2>
      {isLoading ? (
        <p className='mt-5 textcenter'>Loading data...</p>
      ) : (
        <div className="container mt-5 mb-5">
          <div className="row">
            {categoryData.map((item, index) => {
              const imageFilenames = item.s3Images ? item.s3Images.split(',') : [];
              const firstImage = imageFilenames.length > 0 ? imageFilenames[0].trim() : '';

              // Check if 'valid' is true before displaying the data
              if (item.valid) {
                return (
                  <div
                    key={index}
                    className="col-lg-3 col-md-4 col-sm-6 mb-4"
                    // Use onClick to navigate to the ShopDetailPage with the card's ID
                    onClick={() => navigateToShopDetail(item.objectId)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="creative-card">
                      <div className="img-wrapper">
                        <img
                          src={`${IMG_URL}/${firstImage}`}
                          className="card-img-top"
                          alt={item.altText}
                        />
                      </div>
                      <div className="card-body">
                        <h5 className="card-title shop-name">{item.shopName}</h5>
                        <p className="card-text tag-name">{item.tabName}</p>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null; // Don't render the item if 'valid' is false
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default CategoryDetails;
