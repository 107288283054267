import React from 'react'
import footerLogo from "../../assets/logos.png"
import fb from "../../assets/fb.svg"
import twitter from "../../assets/twitter.svg"
import youtube from "../../assets/youtube-color.svg"
import instagram from "../../assets/instagram.svg"
import whatsapp from "../../assets/whatsapp.png"
import email from "../../assets/email.svg"
import phone from "../../assets/phone.svg"
import "./Footer.scss"
//import { Link, useNavigate } from 'react-router-dom'
import { Link} from 'react-router-dom'

function Footer() {
  //const navigate=useNavigate()
  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="row">
                <div className="col-12 col-md-8">
                  <div className="logo-wrapper">
                    <img src={footerLogo} className="footer-logo" alt="footerLogo" />
                  </div>
                  <div className="d-block mt-3 text-left">
                    <ul className="social p-0">
                      {/* <!-- <li>
                          <a href="https://plus.google.com/b/111879288510711072075/111879288510711072075" target="_blank">
                            <img src="assets/img/svg/googlePlus.svg" alt=""/>
                          </a>
                        </li> --> */}
                      <li>
                        <a href="https://twitter.com/pomplet_deals" target="one">
                          <img src={twitter} alt="twitterIcon" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/pomplet.deals" target="one">
                          <img src={fb} alt="facebookIcon" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/channel/UCLNK-IeIhSdSePQj9PSD1mg" target="one">
                          <img width="35" height="35" src={youtube} alt="youtubeIcon" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/pomplet.deals/" target="one">
                          <img width="35" height="35" src={instagram} alt="instagramIcon" />
                        </a>
                      </li>
                      <li>
                        <a href="https://api.whatsapp.com/send?phone=9880199923" target="one">
                          <img width="35" height="35" src={whatsapp} alt="whatsappIcon" className="bg-white rounded-circle" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* <p className="pt-4 copyright">Copyright © 2023. All rights reserved.</p> */}
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="row">
                <div className="col-12 col-md-4 mt-5 mt-md-0">
                  <div className="useful-links">
                    <h2>Know More</h2>
                    <ul className='p-0'>
                      <li><a href="/">Home</a></li>
                      <li><a href="/aboutus">About us</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-5 mt-md-0">
                  <div className="privacy">
                    <h2>Useful links</h2>
                    <ul className='p-0'>
                      {/* <li><a href="/partners">Partners</a></li>
                      <li><a href="/disclainmer">Disclaimer</a></li> */}
                      <li><a href="/PrivacyPolicy">Privacy Policy</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-5 mt-md-0">
                  <div className="contact-info">
                    <h2>Contact Info</h2>
                    <ul className='p-0'>
                      <li><Link to="#" className='footerEmail'><img width="20" src={email} alt="email" /> &nbsp;&nbsp;prashi.technology@gmail.com</Link></li>

                      {/* <li><a target="one" href="#" className='footerEmail'> <img width="20" src={phone} alt='phone' /> &nbsp;&nbsp;+91 98801 99923</a></li> */}
                      <li><Link to="#" className='footerEmail'><img width="20" src={phone} alt='phone' /> &nbsp;&nbsp;+91 98801 99923</Link></li>
                    </ul>
                    <h2>Download for app </h2>
                    <div className="app-store d-flex">
                        <a href="https://play.google.com/store/apps/details?id=prashi.digi.nammdeals" target="one" class="android" >""</a>
                        {/* <a href="https://play.google.com/store/apps/details?id=prashi.digi.nammdeals" target="one" class="ios"></a> */}
                      </div>
                      {/* <p className="pt-4 copyrights">Copyright © 2023. All rights reserved.</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer