import React, { useState } from 'react';
import './Header.css';
import 'font-awesome/css/font-awesome.min.css';
import logo from "../../assets/logos.png"


const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleRedirect = () => {
    // Replace 'https://example.com' with the URL you want to navigate to
    window.location.href = 'https://play.google.com/store/apps/details?id=prashi.digi.nammdeals';
  };

  return (
    <div className={`navbar-container ${isOpen ? 'open' : ''}`}>
      <div className="container">
        <nav className={`navbar ${isOpen ? 'open' : ''}`}>
        <div className="logo">
  <img src={logo} alt="Logo Alt Text"  style={{ width: '200px' }}/>
</div>
          <div className="content-search">
            <ul className={`menu ${isOpen ? 'open' : ''}`}>
              <li><a href="/">Home</a></li>
              <li><a href="/#categories-section">Categories</a></li>
              
              <li><a href="/aboutus">About Us</a></li>
              <li><button onClick={handleRedirect}>Download App</button></li>
              {/* <li><a href="#">Contact Us</a></li> */}
              <li>
  <a href="/#search-section">
    <i className="fa fa-search"></i>
  </a>
  {/* Render the search input modal conditionally */}
  {isSearchOpen && (
    <div className="search-modal">
      <div className="search-modal-content">
        <input type="text" placeholder="Search" />
        <i className="fa fa-times" onClick={toggleSearch}></i>
      </div>
    </div>
  )}
</li>

            </ul>
          </div>
          <div className="toggle" onClick={toggleMenu}>
            <i className={`fa ${isOpen ? 'fa-times' : 'fa-bars'}`}></i>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
