import React from "react";
import './Loader.css'
import loaderGif from '../../assets/loader.gif'; // Import the GIF

function Loader() {
  return (
    <div className="loader-container">
      {/* Use the imported GIF as the loader */}
      <img src={loaderGif} alt="Loading..." />
      {/* Example loading message */}
      <p>Loading...</p>
    </div>
  );
}

export default Loader;
