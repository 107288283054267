import React, { useEffect } from 'react';
import './Aboutus.scss';

const Aboutus = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about-us-container container mt-5 pt-3 mb-5">
      <h1>About Pomplet</h1>
      <p>Welcome to Pomplet - Your Ultimate Savings Destination!</p>

      <h2>What We Offer:</h2>
      <ul>
        <li>
          <strong>Bidar's Best Deals:</strong> Pomplet brings you an exclusive list of discounts and offers in Bidar. Say goodbye to high shopping bills and hello to increased savings!
        </li>
        <li>
          <strong>All Deals in One Place:</strong> Our main page is your one-stop-shop for all the latest and greatest deals. We've curated a collection that's sure to delight every shopper.
        </li>
        <li>
          <strong>Shop by Categories:</strong> Explore our categorized shops to find exactly what you're looking for. We've made shopping easier than ever.
        </li>
        <li>
          <strong>Find Shop Locations:</strong> Need directions? Use our maps feature to navigate to your desired shop's location hassle-free.
        </li>
        <li>
          <strong>Instant WhatsApp Contact:</strong> Get in touch with your favorite shops or businesses directly through WhatsApp. It's quick and convenient.
        </li>
        <li>
          <strong>Spread the Savings:</strong> Love Pomplet? Share the app with your friends and family, and let them experience the joy of saving too!
        </li>
      </ul>

      <p className="join-savings">Join the Savings Revolution</p>
      <p>
        At Pomplet, we're passionate about helping you save more while you shop. We believe in making your shopping experience not just affordable but also enjoyable.
      </p>

      <p>Thank you for choosing Pomplet. Happy shopping and saving!</p>
      <h2>App Reviews</h2>
      <div className="app-reviews">
        {/* Review 1 */}
        <div className="app-review">
          {/* <h3>Great Savings App</h3> */}
          <div className="star-rating">
            {/* Static star rating */}
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
          </div>
          <p>
          Good app to have, it gives some of the best deals across different categories of shopping in Bidar town. The app is also handy for anyone who is new to the town and want's to know what things are good to buy at which store.          </p>
          <p className="reviewer">- Amit K</p>
        </div>

        {/* Review 2 */}
        <div className="app-review">
          {/* <h3>Awesome Deals!</h3> */}
          <div className="star-rating">
            {/* Static star rating */}
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
          </div>
          <p>
          Helpful information. I can buy baby products on discount in Bidar store itself.
          </p>
          <p className="reviewer">- Nagamma KB</p>
        </div>
        <div className="app-review">
          {/* <h3>Great Savings App</h3> */}
          <div className="star-rating">
            {/* Static star rating */}
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
          </div>
          <p>
          Excellent app for local deals
  </p>
          <p className="reviewer">- KA Bhalki</p>
        </div>
        <div className="app-review">
          {/* <h3>Great Savings App</h3> */}
          <div className="star-rating">
            {/* Static star rating */}
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
         
          </div>
          <p>
          Absolutely good and they are giving discount when we see this app to them
</p>
          <p className="reviewer">-Shaik Shaik</p>
        </div>
    </div>
    </div>
  );
};

export default Aboutus;
