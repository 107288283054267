import React, {  useEffect, useState } from "react";
import axios from "axios";
import { FaSearch } from 'react-icons/fa'; // Assuming you have a library like react-icons installed
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

import { IMG_URL } from "../../config";
// import SearchBar from "../SearchBar/SearchBar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//import { Link } from 'react-router-dom';

import "./Home.scss";

import Loader from "../Loader/Loader";
function getRandomColor() {
  const colors = [
    "#FF5733", // Coral
    "#3498DB", // Blue
    "#E74C3C", // Alizarin
    "#27AE60", // Emerald
    "#9B59B6", // Amethyst
    "#F1C40F", // Sunflower
    "#2ECC71", // Nephritis
    "#1ABC9C", // Turquoise
    "#F39C12", // Orange
    "#34495E"  // Wet Asphalt
  ];
  return colors[Math.floor(Math.random() * colors.length)];
}

function HomePage() {
  const [isLoading, setIsLoading] = useState(true); // Add this state variable

  const [apiData, setApiData] = useState({ results: [] });
  const [filteredData, setFilteredData] = useState([]);
  const [visibleCards, setVisibleCards] = useState(12); 
  const [showAllCategories, setShowAllCategories] = useState(false);
  const toggleCategoriesView = () => {
    setShowAllCategories(!showAllCategories);
  };
  const handleSearch = () => {
    // Handle your search logic here
    console.log('Search query:', searchQuery);
    // Clear the input field
    setSearchQuery('');
  };
  const [selectedCategory, setSelectedCategory] = useState(""); // New state for selected category

  // const toggleView = () => {
  //   if (visibleRows === 5) {
  //     setVisibleRows(apiData.results.length); // Show all rows
  //   } else {
  //     setVisibleRows(5); // Show 5 rows
  //   }
  // };
  const loadMoreCards = () => {
    setVisibleCards(visibleCards + 12); // Add 12 more cards when scrolled
  };
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Adjust the number of slides to show
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    axios
      .get(
        "https://pcyag3x4st4hfy6a4ta4jf72du0myucn.lambda-url.ap-south-1.on.aws/"
      )
      .then((response) => {
        setApiData(response.data);
        setFilteredData(response.data.results);
        setIsLoading(false); // Set loading to false when data is fetched
        console.log("Fetched Data:", response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Set loading to false in case of an error

      });
  }, []);

  useEffect(() => {
    // Filter data based on selected category
    let filteredResults = apiData.results;

    if (selectedCategory) {
      filteredResults = filteredResults.filter(
        (item) => item.tabName.toLowerCase() === selectedCategory.toLowerCase()
      );
    }

    // Continue with other filters (searchQuery, visibleCards)
    if (searchQuery) {
      filteredResults = filteredResults.filter(
        (item) =>
          item.shopName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.tabName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredData(filteredResults);
  }, [searchQuery, selectedCategory, apiData.results]);

  const startIndex = 0;
  const endIndex = Math.min(visibleCards, filteredData.length);
  const currentDisplayedData = filteredData.slice(startIndex, endIndex);

  const [popularImages, setPopularImages] = useState({ results: [] });

  const videos = "lKdit9BO7y4,bLKMz9YfAP8";//,fa6baO2A5bM"; // ,sR6tn6nP0sw";

  useEffect(() => {
    axios
      .get(
        "https://wfkwedjtdvugrw5rdvo3eqaz640rzgya.lambda-url.ap-south-1.on.aws/"
      )
      .then((response) => {
        setPopularImages(response.data.results);
        console.log("Fetched Data:", response.data); // Log the fetched data
      })
      .catch((error) => {
        console.error("Error fetching popular deal images:", error);
      });
  }, []);
  

  const uniqueCategories = Array.from(new Set(apiData.results.map(item => item.tabName)));
  const sortedData = currentDisplayedData.slice().sort((a, b) => a.order - b.order);

  return (
    <div>
    <div
      className="banner-section"
       >
      {/* Your banner content */}
    </div>
    <div
      className="video-section"
       >
      {
        videos.split(",").map((videoId, index) => (
          <iframe
            key={index}
           className="youtube"
            src={`https://www.youtube.com/embed/${videoId}`}
            title={`YouTube video player ${index + 1}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        ))
      }
    </div>
    {isLoading ? (
      // Display the loader after the banner section while isLoading is true
      <Loader />
    ) : (
      // Render the rest of your content when isLoading is false
      <div>
        {/* popular section */}
      <div className="container">
      <h2 className="popular-deals-title">Popular Deals</h2>

      </div>
      <div className="popular-deals-section container">
      <div className="popular-deals-carousel">
  <Slider {...settings}>
    {Array.isArray(popularImages) && popularImages.length > 0 ? (
      popularImages
        .filter((image) => image.valid) // Filter out invalid images
        .sort((a, b) => a.order - b.order) // Sort by the 'order' property
        .map((image, index) => (
          <div key={index} className="carousel-card">
            <div className="image-wrapper">
              <img src={image.url} className="img-fluid" alt={`Popular Deal ${index}`} />
            </div>
          </div>
        ))
    ) : (
      <div>
        {/* Add a placeholder or message when no valid images are available */}
        No popular deal images available.
      </div>
    )}
  </Slider>
</div>

</div>
<div  id="search-section" >

</div>
    {/* search  */}
    <div className="search-section container mt-5">
      <h2 className="text-center">Search Section</h2>
      <div className="search-input">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <FaSearch className="input-icon" />
        <button className="search-button" onClick={handleSearch}>
          Clear
        </button>
      </div>
    </div>
    {/* <div className="category-container container ">
        {uniqueCategories.map((category, index) => (
          <div
            className={`category-card ${selectedCategory === category ? "selected" : ""}`}
            key={index}
            onClick={() => setSelectedCategory(category)}
          >
            <div className="category-initial">{category[0]}</div>
            <h3>{category}</h3>
          </div>
        ))}
      </div> */}
      {/* Categories */}
      <div id="categories-section" className="category-container container">
      {uniqueCategories.slice(0, showAllCategories ? uniqueCategories.length : 6).map((category, index) => (
  <div
    className={`category-card ${selectedCategory === category ? "selected" : ""}`}
    key={index} 
    onClick={() => navigate(`/Bidar/${category}`)}

  >
    
      <div className="category-initial" style={{ background: getRandomColor() }}>{category[0]}</div>
      <h3>{category}</h3>
 
  </div>
))}
  <div className="category-actions">
    {uniqueCategories.length > 6 && (
      <button onClick={toggleCategoriesView} className="view-more-btn">
        {showAllCategories ? <FaAngleUp /> : <FaAngleDown />}
      </button>
    )}
  </div>
</div>

  {/* filter the categories */}
  <div className="category-filter container mt-3">
        <label htmlFor="categorySelect">Filter by Category:</label>
        <select
          id="categorySelect"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="">All Categories</option>
          {uniqueCategories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
  {/* card */}
  <div className="container mt-5 mb-5">
      <div className="row">
        {sortedData.map((item, index) => {
          const imageFilenames = item.s3Images ? item.s3Images.split(",") : [];
          const firstImage = imageFilenames.length > 0 ? imageFilenames[0].trim() : "";

          // Check if 'valid' is true before displaying the data
          if (item.valid) {
            return (
              <div
                key={index}
                className="col-lg-3 col-md-4 col-sm-6 mb-4"
                // Use onClick to navigate to the ShopDetailPage with the card's ID
                onClick={() => navigate(`/shop/${item.objectId}`)}
                style={{ cursor: "pointer" }}
              >
                <div className="creative-card">
                  <div className="img-wrapper">
                    <img
                      src={`${IMG_URL}/${firstImage}`}
                      className="card-img-top"
                      alt={item.altText}
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title shop-name">{item.shopName}</h5>
                    <p className="card-text tag-name">{item.tabName}</p>
                  </div>
                </div>
              </div>
            );
          } else {
            return null; // Don't render the item if 'valid' is false
          }
        })}
      </div>
    </div>
 {visibleCards < filteredData.length && (
        <div className="d-flex justify-content-center mt-3 mb-5">
          <button className="btn btn-primary" onClick={loadMoreCards}>
            Load More
          </button>
        </div>
      )}
    </div>

    
    )}
  </div>

  


    
  );
}

export default HomePage;
