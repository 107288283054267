import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { IMG_URL } from "../../config";
import { Logo_URL } from "../../config";
import "./Shopdetail.css"; // Import your custom CSS file
import whatsapp from "../../assets/whatsapp.png"
import directionIcon from "../../assets/direction.png"; // Import the direction icon image
import Loader from "../Loader/Loader";

function ShopDetail() {
  const { objectId } = useParams();
  const [shopData, setShopData] = useState(null);
  const [showMobileNumber, setShowMobileNumber] = useState(false);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleToggleMobileNumber = () => {
    setShowMobileNumber(!showMobileNumber);
  };
  useEffect(() => {
    axios
      .get("https://pcyag3x4st4hfy6a4ta4jf72du0myucn.lambda-url.ap-south-1.on.aws/")
      .then((response) => {
        // Filter the data to find the object with the matching objectId
        const foundShop = response.data.results.find((item) => item.objectId === objectId);
  
        if (foundShop) {
          setShopData(foundShop);
          // console.log("Shop Data:", foundShop);

        } else {
          console.error("Shop not found for objectId:", objectId);
        }
      })
      .catch((error) => {
        console.error("Error fetching shop data:", error);
      });
  }, [objectId]);

  if (!shopData) {
    return <Loader />;;
  }
// Function to open Google Maps with directions
const openGoogleMaps = () => {
  const { latitude, longitude } = shopData.coords;
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;

  // Detect the user's platform (Android, iOS, or other)
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  if (isAndroid) {
    // If it's Android, open the URL using the 'intent:' URL scheme
    window.location.href = `intent://maps.google.com/maps/dir/?api=1&destination=${latitude},${longitude}#Intent;package=com.google.android.apps.maps;S.browser_fallback_url=${googleMapsUrl};end`;
  } else if (isIOS) {
    // If it's iOS, open the URL using the 'maps:' URL scheme
    window.location.href = `maps://maps.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
  } else {
    // For other platforms, simply open the URL in a new tab
    window.open(googleMapsUrl, "_blank");
  }
};

  return (
    <div>
    <div className="container mt-5 pt-5  mb-5 text-center">
      {shopData.logoImage && (
        <img
          src={`${Logo_URL}/${shopData.logoImage}`}
          alt={`${shopData.shopName} Logo`}
          className="shop-logo"
        />
      )}
      <h2 className="mt-5">{shopData.shopName}</h2>
      <p className="tag-name"> {shopData.tabName}</p>
      <div className="contact-section">
        <button
          onClick={handleToggleMobileNumber}
          className={`contact-button ${showMobileNumber ? "hide" : "show"}`}
        >
          {showMobileNumber ? "Hide Contact" : "Contact Details"}
        </button>
        {showMobileNumber && (
          <p className="mobile-number">Mobile Number: {shopData.mobileNumber}</p>
        )}
      </div>
       <div className="icon-container">
  <div className="whatsapp-icon">
    <p>Message</p>
    <a
      href={`https://wa.me/${shopData.mobileNumber}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={whatsapp} alt={`${shopData.shopName} WhatsApp`} />
    </a>
  </div>
  <div className="direction-icon" onClick={openGoogleMaps}>
    <p>Direction</p>
    <img src={directionIcon} alt={`${shopData.shopName} Directions`} />
  </div>
</div>

      <div className="image-grid my-masonry-grid">
          {shopData.s3Images.split(",").map((imageName, index) => (
            <div className="my-masonry-grid_column" key={index}>
              <img
                src={`${IMG_URL}/${imageName.trim()}`}
                alt={shopData.shopName}
                className="img-item"
              />
            </div>
          ))}
        </div>

     <div>

     </div>
      {shopData.videoIds && (
        shopData.videoIds.split(",").map((videoId, index) => (
          <iframe
            key={index}
           className="youtube"
            src={`https://www.youtube.com/embed/${videoId}`}
            title={`YouTube video player ${index + 1}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        ))
      )}


      {/* Add more shop details here */}
    </div>
  </div>
  );
}

export default ShopDetail;
