import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./components/Home/Home";
import ShopDetail from "./components/Shopdetail/Shopdetail";
import Footer from "./components/Footer/Footer";
import Aboutus from "./components/Aboutus/Aboutus";
import CategoryDetails from "./components/CategoryDetails/CategoryDetails";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import GoogleAnalytics from './GoogleAnalytics'

function App() {
  return (
    <BrowserRouter>
      <Header />
      <GoogleAnalytics />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/aboutus" element={<Aboutus/>} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />

        <Route path="/bidar/:category"  element={<CategoryDetails/>} /> {/* Define the route for category details */}

        <Route path="/shop/:objectId" element={<ShopDetail />} /> {/* Define the route for the ShopDetail */}
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
